a.page-scroll {
  display: inline-block;
  position: relative;
  text-align: center;
  color: $color-menu-main;
  text-decoration: none;
  overflow: hidden;

  &:after {
    content: '';
    position: absolute;
    background: $color-menu-main;
    height: 2px;
    width: 0;
    transform: translateX(-50%);
    left: 50%;
    bottom: 0;
    transition: .35s ease;
  }

  &:hover:after {
    width: 100%;
  }
}

.button_container {
  position: fixed;
  top: 4%;
  right: 4%;
  height: $menu-button-height;
  width: $menu-button-width;
  cursor: pointer;
  z-index: 501;
  transition: opacity .25s ease;

  &:hover {
    opacity: .7;
  }

  &.active {
    .top {
      transform: translateY(10px) translateX(0) rotate(45deg);
      background: $color-menu-active;
    }
    .middle {
      opacity: 0;
      background: $color-menu-active;
    }

    .bottom {
      transform: translateY(-10px) translateX(0) rotate(-45deg);
      background: $color-menu-active;
    }
  }

  span {
    background: $color-menu-main;
    border: none;
    height: 3px;
    width: 100%;
    position: absolute;
    top: 0;
    left: 0;
    transition: all .35s ease;
    cursor: pointer;

    &:nth-of-type(2) {
      top: 10px;
    }

    &:nth-of-type(3) {
      top: 20px;
    }
  }
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  opacity: 1;
  visibility: hidden;
  transition: opacity .35s, visibility .35s, width .35s;
  z-index: 500;

  &:before {
    content: '';
    background: #900030;
    left: -55%;
    top: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    transition: left .35s ease;
  }

  &:after {
    content: '';
    background: #900030;
    right: -55%;
    top: 0;
    width: 50%;
    height: 100%;
    position: absolute;
    transition: all .35s ease;
  }

  &.open {
    opacity: .9;
    visibility: visible;
    height: 100%;

    &:before {
      left: 0;
    }

    &:after {
      right: 0;
    }

    li {
      animation: fadeInRight .5s ease forwards;
      animation-delay: .35s;

      &:nth-of-type(2) {
        animation-delay: .45s;
      }
      &:nth-of-type(3) {
        animation-delay: .55s;
      }
      &:nth-of-type(4) {
        animation-delay: .65s;
      }
    }
  }

  nav {
    position: relative;
    height: 50%;
    top: 50%;
    transform: translateY(-40%);
    font-weight: 400;
    text-align: center;
    z-index: 501;
    font-size: 22px;
  }

  ul {
    list-style: none;
    padding: 0;
    margin: 0 auto;
    display: inline-block;
    position: relative;
    height: 100%;

    li {
      display: block;
      height: 25%;
      height: calc(100% / 6);
      min-height: 50px;
      position: relative;
      opacity: 0;

      a {
        display: block;
        position: relative;
        color: $color-menu-link;
        text-decoration: none;
        overflow: hidden;

        &:hover:after,
        &:focus:after,
        &:active:after {
          width: 100%;
        }

        &:after {
          content: '';
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 0%;
          transform: translateX(-50%);
          height: 3px;
          background: $color-menu-link;
          transition: .35s;
        }
      }

      &.active a:after {
        width: 100%;
      }
    }
  }
}

@include media-breakpoint-up(sm) {
  .overlay {
    nav {
      font-size: 35px;
    }
  }
}

@include media-breakpoint-up(xs) {
  .button_container {
    top: 3%;
    width: 30px;
  }

  .overlay ul li {
    height: calc(100% / 6);
  }
}

@keyframes fadeInRight {
  0% {
    opacity: 0;
    left: 20%;
  }
  100% {
    opacity: 1;
    left: 0;
  }
}