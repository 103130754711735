footer#kontakt {
  textarea {
    resize: none;
    height: 120px;
  }

  p {
    margin-bottom: 35px;
  }

  margin-top: 70px;

  .fa.contacts-icon {
    font-size: 20px;
    color: #900030;
    margin-bottom: 20px;
  }

  a {
    color: #900030;
  }

  a:hover, a:focus {
    color: #d2204d;
  }
}

@include media-breakpoint-up(sm) {
  .fa.contacts-icon {
    font-size: 25px;
  }
}