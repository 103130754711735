.noscroll {
  overflow: hidden;
}

a {
  display: inline-block;
}



h2 {
  margin: 40px 0;
  text-align: center;
  font-size: 2.2em;
  font-weight: 300;
  letter-spacing: 0.04em;
}

h3 {
  font-size: 1.55rem;
}

.fancy-title {
  font-weight: 500;
  font-size: 17px;
}

.fancybox-caption {
  p {
    padding-top: 10px;
  }
}

.fill {
  min-height: 100%;
  height: 100%;
}

.text-danger {
  color: #900030 !important;
}

.help-block {
  font-size: 15px;
}

.form-control:focus {
  border-color: #900030;
}

.btn-primary {
  color: #fff;
  background-color: #900030;
  border-color: #900030;
}

.btn-primary:hover, .btn-primary:active {
  color: #fff;
  background-color: #da3660;
  border-color: #da3660;
}

.btn-primary:focus, .btn-primary.focus {
  box-shadow: 0 0 0 2px rgb(144, 0, 48);
}

.form-control {
  height: 50px;
  line-height: 50px;
  padding: 0 12px;
  font-weight: 300;
  border: 0;
  color: #f0f0f1;
  background-color: transparent;
  border-top: 1px solid #757575;
  border-bottom: 1px solid #757575;
  border-radius: 0;
}

.top-spaced {
  margin-top:20px;
}