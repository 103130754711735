@-webkit-keyframes uparrow {
  0% {
    -webkit-transform: translateY(0);
    //color: #000;
  }
  100% {
    -webkit-transform: translateY(-0.4em);
    //color: $color-primary-0;
  }
}

@-webkit-keyframes downarrow {
  0% {
    -webkit-transform: translateY(0);
    //color: #000;
  }
  100% {
    -webkit-transform: translateY(0.4em);
    //color: $color-primary-0;
  }
}

section {
  position: relative;
  padding-top: 3em;
  padding-bottom: 3em;
}

.page-arrow {
  position: absolute;
  left: 0;
  right: 0;
  bottom: 25px;
}

.arrow {
  display: inline-block;
}

.up {
  animation: uparrow 0.6s infinite alternate ease-in-out;
}

.down {
  animation: downarrow 0.6s infinite alternate ease-in-out;
  color: #d8054d;

  &:hover {
    color: darken(#d8054d, 10%);
  }
}

@include media-breakpoint-up(sm) {
  section {
    padding-top: 6em;
    padding-bottom: 6em;
  }
}