#uvod {
  position: relative;
  margin: 0;
  padding: 0 0 10px 0;

  &:before {
    position: absolute;
    content: '';
    pointer-events: none;
    right: 0;
    left: 0;
    z-index: 10;
    display: block;
    height: 90px;
    background-size: 50px 100%;
    top: 100%;
    background-image: -webkit-gradient(linear, 0 0, 300% 100%, color-stop(0.25, #fff), color-stop(0.25, #fff));
    background-image: linear-gradient(135deg, #fff 25%, transparent 25%), linear-gradient(225deg, #fff 25%, transparent 25%);
    background-position: 50%;
  }

  .lead {
    font-weight: 300;
    letter-spacing: 0;
    line-height: 1.2;
  }

  p.lead {
    margin-bottom: 0;
  }

  .moto {
    font-weight: 400;
    font-size: 1.2em;
  }

  .moto-text {
    font-size: 0.8em;
  }

  .moto-last {
    font-size: 0.8em;
    font-weight: 400;
    color: #da0f54;
    letter-spacing: 1px;
  }

  a {
    img {
      width:  80%;
    }
  }

  .fa-3x {
    font-size: 2em;
  }
}

@include media-breakpoint-up(md) {
  #uvod {

    a {
      img {
        width: 50%;
      }
    }

    .lead {
      font-size: 1.5rem;
    }
  }
}

@include media-breakpoint-down(sm) {
  #uvod:before {
    height: auto;
  }
}