@import url('https://fonts.googleapis.com/css?family=Open+Sans:300,400,700&subset=latin-ext');

@import 'bootstrap/bootstrap';
@import 'font-awesome';
@import 'components/variables';

* {
  outline: 0 !important;
}

html, body {
  height:100%;
}

body {
  font-family: 'Open Sans', sans-serif;
  letter-spacing: 1.8px;
  font-weight: 300;
  font-size: $fs-lg;

  @include media-breakpoint-up(xs) {
    font-size: $fs-xs;
  }

  @include media-breakpoint-up(md) {
    font-size: $fs-sm;
  }

  @include media-breakpoint-up(xl) {
    font-size: $fs-md;
  }
}

@import 'components/tags';
@import 'components/header';
@import 'components/content';
@import 'components/page-intro';
@import 'components/page-offer';
@import 'components/page-portfolio';
@import 'components/about-us';
@import 'components/footer';