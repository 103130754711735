#ukazky-me-prace {

  h2 {
    margin-top: 0;
  }

  .item {
    margin-top: 0;
    padding: 0 15px;

    .img-box {
      position: relative;
      overflow: hidden;
    }

    img {
      transition: all 0.3s;
      max-width: 350px;
      padding-bottom: 30px;
    }


    &:hover {
      img {
        transform: scale(1.1);
      }
    }
  }

  .gallery-link {
    text-decoration: none;
    color: inherit;
    display: block;

    h3 {
      margin: 10px 0 0 0;
      min-height: 40px;
      letter-spacing: 0;
      padding-bottom: 10px;
    }
  }

  &:before,
  &:after {
    position: absolute;
    content: '';
    pointer-events: none;
    top: -70px;
    left: 0;
    z-index: 10;
    width: 50%;
    height: 70px;
    background: #fff;
  }

  &:before {
    border-radius: 0 40px 0 0;
  }

  &:after {
    left: 50%;
    border-radius: 40px 0 0 0;
  }
}

.gallery-container {
  display: none;
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow-y: auto;
  z-index: 600;

  .fancybox-bg {
    opacity: .87;
    height: 100%;
    position: fixed;
  }

  .fancybox-button {
    background: transparent;
    font-size: 35px;

    &:before, &:after {
      display: none;
    }
  }

  .fancybox-button:hover:not([disabled]) {
    background: transparent;
    color: #fff;
  }

  .fancybox-button--close {
    position: fixed;
    top: 5px;
    right: 17px;
    float: none;
    z-index: 620;
  }

  .no-items {
    position: absolute;
    top: 50%;
    left: 0;
    color: #fff;
    text-align: center;
  }

  .items {

    .item {
      position: relative;
      text-align: center;
      margin: 30px 0;
      padding-bottom: 0;
      padding-top: 0;

      .thumbnails {
        a {
          padding: 5px;
          border: 1px solid #fff;
          margin-bottom: 10px;
        }
      }

      &:after {
        position: absolute;
        bottom: 0;
        left: 0;
        right: 0;
        width: 80%;
        margin: auto;
        content: '';
        display: block;
        border-bottom: 1px dotted #fff;
      }
    }
  }

  &.active {
    display: block;
  }
}

@include media-breakpoint-down(sm) {
  #ukazky-me-prace:before,
  #ukazky-me-prace:after {
    display: none;
  }
}

@include media-breakpoint-up(sm) {
  h2 {
    margin-top: -20px;
  }

  .item {
    margin-top: 40px;

    img {
      padding-bottom: 0;
    }
  }

  .gallery-link {
    h3 {
      min-height: 60px !important;
      padding-bottom: 0;
    }
  }

  .gallery-container {
    .items {
      .item {
        margin: 80px 0;
      }
    }

    .fancybox-button {
      font-size: 55px;
    }
  }
}