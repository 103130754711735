#me-sluzby {
  background-color: #900030;
  color: rgba(255, 255, 255, 0.85);
  padding-bottom: 10em;

  h2 {
    color: #fff;
  }

  p.offer-text {
    font-size: 1.075em;
    font-weight: 400;
  }
}

.offers {

  .offer {
    position: relative;
    line-height: 0;
    overflow: hidden;
    background: transparent;
    transition: all 0.3s ease-in-out;
    padding: 15px;

    .offer-overlay {
      position: absolute;
      top: 15px;
      left: 15px;
      width: calc(100% - 30px);
      height: calc(100% - 30px);
      border-radius: .25rem;
      background-color: #40382b;
      background-image: radial-gradient( #40382b, black, black);
      opacity: 0;
      transition: inherit;
    }

    img {
      width: 100%;
      height: 100%;
      transition: inherit;
    }

    .offer-details {
      position: absolute;
      top: 55%;
      width: 95%;
      width: calc(100% - 30px);
      opacity: 0;
      padding-left: 1em;
      padding-right: 1em;
      line-height: normal;
      text-align: center;
      transform: translateY(-50%);
      transition: all 0.4s ease-out;
    }

    .offer-title {
      margin-bottom: 0.5em;
      margin-top: 0.5em;
      color: #d3c6af;
      letter-spacing: 0.25em;
      text-transform: uppercase;
      font-weight: 500;
    }

    .offer-description {
      margin-bottom: 1.5em;
      font-size: 1.1em;
      color: #c5b395;
    }

    &:hover {
      cursor: pointer;

      .offer-overlay {
        opacity: 0.8;
      }

      .offer-details {
        top: 50%;
        opacity: 1;
      }
    }
  }
}

@include media-breakpoint-down(sm) {
  #me-sluzby {
    padding-bottom: 2em;

    h2 {
      margin-top: 0.2em;
    }
  }

  .offers {
    .offer {
      .offer-title {
        font-size: 1.2em;
      }
      .offer-description {
        font-size: .9em;
      }
    }
  }
}