// SASS style sheet */
// Palette color codes */
// Palette URL: http://paletton.com/#uid=35o0u0kw0u6klBdpNwPzanOEYi- */

// Feel free to copy&paste color codes to your application */


// As hex codes */

$color-primary-0: #D50044;	// Main Primary color */
$color-primary-1: #E85584;
$color-primary-2: #E32C67;
$color-primary-3: #A80036;
$color-primary-4: #83002A;

$color-secondary-1-0: #00A65A;	// Main Secondary color (1) */
$color-secondary-1-1: #46BF88;
$color-secondary-1-2: #23B271;
$color-secondary-1-3: #008347;
$color-secondary-1-4: #006638;

$color-secondary-2-0: #C3E800;	// Main Secondary color (2) */
$color-secondary-2-1: #DFF85B;
$color-secondary-2-2: #D7F730;
$color-secondary-2-3: #9AB800;
$color-secondary-2-4: #788F00;

$color-bg: #f0f0f1;
$text-primary: #333;

$color-menu-background: #F5F5F5; //#F5F5F5;
$color-menu-main: $color-primary-0;// #FF5252;
$color-menu-active: #FFF;
$color-menu-link: #FFF;

$menu-button-height: 27px;
$menu-button-width: 35px;

$fs-lg: 18px;
$fs-md: 17px;
$fs-sm: 16px;
$fs-xs: 15px;